import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef, inject, DestroyRef
} from '@angular/core';
import { pluck, filter, tap, map, finalize } from 'rxjs/operators';
import { JobsStateFacade } from 'src/core/store';
import { Observable, Subject } from 'rxjs';
import {
  IJobViewModel,
  EmployerPipelineViewModel,
  ClickoutJobViewModel,
  HostedJobViewModel,
  Question
} from '@core/view-models';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LocationService } from '../../../core/services';
import { HttpHeaders } from '@angular/common/http';
import { ScriptService } from '@core/services/scripts.service';
import {
  EXPIRED_JOB_DESCRIPTION,
  DEFAULT_THANKS_PAGE,
} from '@shared/consts';
import { snakeCase } from 'snake-case';
import { environment } from '../../../environments/environment';
import { QuestionControlService } from 'src/common/services';
declare let fbq: Function;
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TService } from '@core/translations/t.service';

@Component({
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.less']
})
export class JobsPageComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  private readonly tService = inject(TService);

  @ViewChild('clickoutJobTemplate', { static: true })
  clickoutJobTemplate: TemplateRef<any>;

  loading: boolean;
  jobs$: Observable<IJobViewModel[]>;
  selectedJob$: Observable<HostedJobViewModel | IJobViewModel>;
  location: string;
  keyword: string;
  pagesInfo$: Observable<{
    total: number;
    currentPage: number;
    perPage: number;
  }>;
  jobSelected: IJobViewModel;
  employerPipeline$: Observable<EmployerPipelineViewModel>;
  isPipelineEnabled$: Observable<boolean>;
  showRedirectToEmloyerPage: boolean;
  clickoutJob$: Observable<ClickoutJobViewModel>;
  url: any;
  hasJobs$: Observable<boolean>;
  questions$: Observable<Question[]>;
  message: string;
  submitted: boolean;
  locationLoaded = false;
  organicJobParams: { cs: any; exch: any; jobId: any };
  showLocationTooltip = false;

  constructor(
    private jobsState: JobsStateFacade,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private questionsSerice: QuestionControlService,
    private scriptsService: ScriptService,
    private locationService: LocationService
  ) {
    this.jobs$ = this.jobsState.jobs$;
    this.hasJobs$ = this.jobsState.jobs$.pipe(
      map(jobs => jobs && !!jobs.length)
    );
    this.selectedJob$ = this.jobsState.selectedJob$.pipe(filter(j => !!j));
    this.questions$ = this.selectedJob$.pipe(
      filter(job => !job.haveSecondPageQuestions),
      pluck('questions')
    );

    this.selectedJob$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({
          questions,
          employerId,
          thankYouPageBody,
          isExpired,
          haveSecondPageQuestions,
          secondPageId,
          ...rest
        }) => {
          this.jobSelected = {
            hostedParams: this.organicJobParams,
            ...this.jobSelected,
            ...rest,
            questions,
            employerId,
            thankYouPageBody,
            haveSecondPageQuestions,
            secondPageId
          };

          this.organicJobParams = null;

          if (isExpired) {
            this.message = EXPIRED_JOB_DESCRIPTION;
          }
        }
      );

    this.employerPipeline$ = this.jobsState.employerPipeline$;
    this.clickoutJob$ = this.jobsState.clickoutJob$;
    this.isPipelineEnabled$ = this.jobsState.employerPipeline$.pipe(
      filter(p => !!p),
      pluck('enabled')
    );
    this.pagesInfo$ = this.jobsState.pages$;

    this.jobsState.pixelSource$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(src => this.scriptsService.insertScript(src));

    this.jobsState.isNotQualifiedApply$
      .pipe(
        filter(notQualified => notQualified),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.message = this.tService.translate('message.knockout_not_fit');
      });

    this.jobsState.redirectUrl$
      .pipe(
        filter(url => !!url),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(url => {
        window.open(url);
      });
  }

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.location = queryParams.location || '';
    this.keyword = queryParams.keyword || '';

    const skipLocationDetection = queryParams.hasOwnProperty('skipLocationDetection');

    if (!skipLocationDetection && !this.location) {
      this.locationService.getUserLocation().subscribe({
        next: userLocation => {
          this.location = userLocation || '';
          if (userLocation) {
            this.showLocationTooltip = true;
          }
        },
        complete: () => {
          this.search({ keyword: this.keyword, location: this.location });
          this.subscribeToQueryParams();
        }
      });
    } else {
      this.search({
        keyword: this.keyword,
        location: this.location
      });

      this.subscribeToQueryParams();
    }

    this.clickoutJob$
      .pipe(
        filter(job => !!job),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(job => {
        if (new URL(job.url).hostname === window.location.hostname) {
          window.location.href = job.url;
          return;
        }

        this.dialog.open(this.clickoutJobTemplate, {
          data: job,
          width: '90%',
          panelClass: 'no-padding-panel',
          height: '80%',
          maxWidth: '90%',
          disableClose: true
        });
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(job.url);
      });
  }

  ngOnDestroy() {}

  subscribeToQueryParams(): void {
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params: Params) => {
        this.showRedirectToEmloyerPage = params.jg && params.jg.length;

        this.loadData(params);
      });
  }

  formSubmitHandler(form) {
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });

    const params = this.jobSelected.hostedParams ? Object.entries(this.jobSelected.hostedParams).reduce(
      (acc, [k, v]) => {
        acc[snakeCase(k)] = v;
        return acc;
      },
      {}
    ) : {};

    const formData = this.questionsSerice.toFormData(
      form,
      this.jobSelected.questions,
      {
        employerId: this.jobSelected.employerId,
        id: this.jobSelected.secondPageId
      },
      params
    );

    delete this.jobSelected.haveSecondPageQuestions;

    this.loading = true;
    this.jobsState
      .submitAnswers(this.jobSelected.hostedParams?.jobId, formData, { headers })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(({ jobs }) => {
        if (this.jobSelected.haveSecondPageQuestions || jobs.redirectUrl) {
          return;
        }
        if (!jobs.isNotQualified) {
          this.message =
            this.jobSelected.thankYouPageBody || DEFAULT_THANKS_PAGE;
        }
        this.submitted = true;
      });
  }

  selectJob(job: IJobViewModel): void {
    if (job.useDirectClickout) {
      if (environment.useFacebookPixel) {
        fbq('trackCustom', 'job_click');
      }
      this.openWindow(job.url);
      return;
    }

    this.submitted = false;
    this.message = null;
    if (job.hostedParams) {
      this.loadSelectedJobInfo(job.id, job.hostedParams);
    } else {
      this.jobsState.selectJob(job.id)
    }
  }

  private loadSelectedJobInfo(jobRef, { cs, exch, jobId }): void {
    this.loading = true;
    this.jobsState
      .loadSelectedJob(jobId, jobRef, { cs, exch })
      .subscribe(() => (this.loading = false));
  }

  openWindow(url) {
    window.open(url);
  }

  goToJob(url: string) {
    window.open(
      url,
      '_blank',
      'location=yes,height=570,width=720,scrollbars=yes,status=yes'
    );
  }

  search({
    keyword,
    location,
    page
  }: {
    keyword: string;
    location: string;
    page?: number;
  }): void {
    this.locationLoaded = true;
    this.jobSelected = null;
    this.keyword = keyword;
    this.location = location;

    const { cs, exch, orgj_id } = this.route.snapshot.queryParams;
    if (cs && exch && orgj_id) {
      this.organicJobParams = {
        cs,
        exch,
        jobId: orgj_id
      };
    }

    if (this.organicJobParams) {
      this.loadSelectedJobInfo(this.jobSelected?.id, this.organicJobParams);

      /// capturing emails feature is temporary hidden
      // this.dialog.open(EmailSubscriberDialogComponent, {
      //   width: '90%',
      //   maxWidth: '500px',
      //   panelClass: 'subscribe-dialog',
      //   data: {
      //     keyword,
      //     location,
      //     activationType: EmailActivationType.organic
      //   }
      // });
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        keyword,
        location,
        page,
        cs: null,
        exch: null,
        orgj_id: null,
        skipLocationDetection: null,
      },
      queryParamsHandling: 'merge'
    });
  }

  pageChanged(page: number): void {
    this.search({ keyword: this.keyword, location: this.location, page });
  }

  private loadData(params: any): void {
    const keyword = params.keyword || '';
    const location = params.location || ' ';
    const page = params.page || 0;

    const requestParams = { ...params };
    delete requestParams.keyword;
    delete requestParams.location;
    delete requestParams.page;
    delete requestParams.skipLocationDetection;

    this.loading = true;
    this.jobsState
      .loadJobs(keyword, location, page, requestParams)
      .subscribe(() => (this.loading = false));
  }
}
