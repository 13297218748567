import { Injectable } from '@angular/core';
import { DE_LABELS } from './de-labels';
import { EN_LABELS } from './en-labels';

const LABELS = {
  default: EN_LABELS,
  DE: DE_LABELS
};

@Injectable({ providedIn: 'root' })
export class TService {
  countryCode = new URLSearchParams(window.location.search).get('country_code');

  public translate(label: string): string {
    if (!label) {
      return '';
    }

    const labelFile = LABELS[this.countryCode] ?? LABELS.default;
    const current = labelFile[label] ?? LABELS.default[label];

    if (current) {
      return current;
    }

    const keys = label.split('.');

    return keys[keys.length - 1];
  }
}
